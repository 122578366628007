/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

$theme-colors: (
    "primary": #007bff,
    "secondary": #6c757d,
    "success": #28a745,
    "info": #17a2b8,
    "warning": #ffc107,
    "danger": #dc3545,
    "light": #f8f9fa,
    "dark": #343a40
);

$font-family: 'Times New Roman', serif;
$font-family-monospace: 'Roboto Mono', monospace;
$font-size: calc(10px + 1vmin);

.mei {
    font-size: calc(10px + 0.5vmin);
    color: $secondary;
}

.form {
    font-size: calc(10px + 1.2vmin);
}

.header {
    font-size: calc(10px + 7vmin);
}

.vow {
    font-size: calc(10px + 1.5vmin);
}

blockquote {
    font-size: calc(10px + 1vmin);
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
  }
  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote p {
    display: inline;
  }


.floating-emoji {
    animation: float 2s ease-in-out infinite;
}

@keyframes float {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.thinking-deeply-button {
    font-size: calc(10px + 1.2vmin);
}